angular
  .module('app')
  .service('callMonitorService', callMonitorService);

/** @ngInject */
function callMonitorService($log, $q, toaster, callMonitorFactory) {
  var self = {
    // OBJECTS
    callData: {},

    // FLAGS
    isGettingCallData: null,

    // METHODS
    getCallData: function (email) {
      if (self.isGettingCallData) {
        return;
      }

      self.isGettingCallData = true;

      var currentEmail = '';
      var defer = $q.defer();

      if (email) {
        currentEmail = email;
      } else {
        currentEmail = localStorage.getItem('currentUserEmail');
      }

      callMonitorFactory
        .get({
          email: currentEmail
        })
        .$promise
        .then(function (response) {
          self.callData = response;

          self.isGettingCallData = false;

          defer.resolve();
        }, function () {
          self.callData = {};

          self.isGettingCallData = false;
        });

      return defer.promise;
    }
  };

  return self;
}
