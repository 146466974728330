angular
  .module('app')
  .service('localizationService', localizationService);

/** @ngInject */
function localizationService() {
  var self = {
    // OBJECTS
    alternativeAddress: {},
    groundZero: {
      latitude: -34.6157437,
      longitude: -58.5733832
    },
    selectedCityBounds: {
      northeast: {
        latitude: null,
        longitude: null
      },
      southwest: {
        latitude: null,
        longitude: null
      }
    },

    // FLAGS
    addressIsFromAnotherCity: null,
    isGettingAddress: null,
    isGettingGeocode: null,
    mapLevel1IsVisible: null,
    mapLevel2IsVisible: null,
    mapLevel3IsVisible: true
  };

  return self;
}
