angular
  .module('app')
  .service('cityService', cityService);

/** @ngInject */
function cityService($log, getTopCitiesFactory, searchCitiesFactory) {
  var self = {
    // ARRAYS
    cities: [],
    topCities: [],

    // OBJECTS
    selectedCity: {},

    // NUMBERS
    limit: 24,

    // FLAGS
    hasMoreTopCities: true,
    ignoreDifferentCity: null,
    isGettingMoreTopCities: null,
    isGettingTopCities: null,
    isSearchingCities: null,

    // METHODS
    getMoreTopCities: function () {
      if (self.isGettingMoreTopCities) {
        return;
      }

      var partner = '';

      if (self.hasMoreTopCities) {
        self.isGettingMoreTopCities = true;

        getTopCitiesFactory
          .query({
            limit: self.limit,
            offset: self.topCities.length,
            partner: partner
          })
          .$promise
          .then(function (response) {
            self.topCities = self.topCities.concat(response);
            self.cities = self.topCities;

            if (response.length === 0) {
              self.hasMoreTopCities = false;
            }

            self.isGettingMoreTopCities = false;
          }, function (reason) {
            $log.error('cityService.getMoreTopCities', reason);

            self.isGettingMoreTopCities = false;
          });
      }
    },
    getTopCities: function (force, leadSource) {
      if (!force && sessionStorage.getItem('topCities')) {
        self.topCities = angular.fromJson(sessionStorage.getItem('topCities'));

        return;
      }

      if (self.isGettingTopCities) {
        return;
      }

      self.isGettingTopCities = true;

      var partner;

      if (leadSource) {
        partner = leadSource.toLowerCase();

        if (
          partner === 'adwords' ||
          partner === 'googlemerchant' ||
          partner === 'branding' ||
          partner === 'branding_balneariocomboriu' ||
          partner === 'branding_blumenau' ||
          partner === 'branding_brasilia' ||
          partner === 'branding_criciuma' ||
          partner === 'branding_curitiba' ||
          partner === 'branding_florianopolis' ||
          partner === 'branding_fortaleza' ||
          partner === 'branding_niteroi' ||
          partner === 'branding_recife' ||
          partner === 'branding_riodejaneiro' ||
          partner === 'branding_saojose' ||
          partner === 'branding_saoluis' ||
          partner === 'branding_saopaulo' ||
          partner === 'olx' ||
          partner === 'organico' ||
          partner === 'portal_v5' ||
          partner === 'portalbanner' ||
          partner === 'portalwidget' ||
          partner === 'mourafacil'
        ) {
          partner = '';
        }
      }

      getTopCitiesFactory
        .query({
          limit: self.limit,
          offset: 0,
          partner: partner
        })
        .$promise
        .then(function (response) {
          self.topCities = response.map(function (city) {
            city.cityNormalized = city.city.normalize('NFKD').replace(/[\u0300-\u036F]/g, '');

            return city;
          });

          self.cities = self.topCities;

          sessionStorage.setItem('topCities', angular.toJson(self.topCities));

          self.isGettingTopCities = false;
        }, function (reason) {
          // if (leadSource) {
          //   toaster.pop('error', 'No hay ciudades vinculadas a la asociación. "' + leadSource + '".');
          // }

          $log.error('cityService.getTopCities', reason);

          self.isGettingTopCities = false;
        });
    },
    searchCities: function (searchTerm) {
      if (self.isSearchingCities) {
        return;
      }

      if (searchTerm) {
        self.isSearchingCities = true;

        searchCitiesFactory
          .query({
            keyword: searchTerm,
            limit: self.limit,
            offset: 0
          })
          .$promise
          .then(function (response) {
            self.cities = response;

            self.isSearchingCities = false;
          }, function (reason) {
            $log.error('cityService.searchCities', reason);

            self.isSearchingCities = false;
          });
      } else {
        self.cities = self.topCities;

        self.isSearchingCities = false;
      }
    }
  };

  self.getTopCities(false);

  return self;
}
