angular
  .module('app')
  .service('ticketsService', ticketsService);

/** @ngInject */
function ticketsService($rootScope, $log, toaster, ticketsFactory) {
  var self = {
    // ARRAYS
    tickets: [],
    sortTermOptions: [
      {
        label: 'Código ⇡',
        value: 'shortId'
      },
      {
        label: 'Código ⇣',
        value: '-shortId'
      },
      {
        label: 'Orden de servicio ⇡',
        value: 'serviceNumber'
      },
      {
        label: 'Orden de servicio ⇣',
        value: '-serviceNumber'
      },
      {
        label: 'Creación ⇡',
        value: 'createdAt-date'
      },
      {
        label: 'Creación ⇣',
        value: '-createdAt-date'
      },
      {
        label: 'Cliente ⇡',
        value: 'customer.name'
      },
      {
        label: 'Cliente ⇣',
        value: '-customer.name'
      },
      {
        label: 'Vehículo ⇡',
        value: 'vehicle'
      },
      {
        label: 'Vehículo ⇣',
        value: '-vehicle'
      },
      {
        label: 'Placa ⇡',
        value: 'customer.licencePlate'
      },
      {
        label: 'Placa ⇣',
        value: '-customer.licencePlate'
      },
      {
        label: 'Batería ⇡',
        value: 'battery.model'
      },
      {
        label: 'Batería ⇣',
        value: '-battery.model'
      },
      {
        label: 'Precio ⇡',
        value: 'battery.finalPrice'
      },
      {
        label: 'Precio ⇣',
        value: '-battery.finalPrice'
      },
      {
        label: 'Estado ⇡',
        value: 'orderStatus'
      },
      {
        label: 'Estado ⇣',
        value: '-orderStatus'
      }
    ],

    // NUMBERS
    limit: 100,
    offset: 0,
    ordersConfirmed: 0,
    ordersOnTheirWay: 0,
    ordersOnLocal: 0,
    ordersFinished: 0,

    // STRINGS
    sortTerm: 'shortId',

    // FLAGS
    isGettingTickets: null,

    // METHODS
    getTickets: function () {
      if (self.isGettingTickets) {
        return;
      }

      self.isGettingTickets = true;

      self.resetTickets();

      ticketsFactory
        .query({
          id: sessionStorage.getItem('cnpj'),
          limit: self.limit,
          offset: self.offset,
          sort: self.sortTerm
        }, function (response) {
          function checkOrdersConfirmed(order) {
            return (
              order.orderStatus === 'Accepted' ||
              order.orderStatus === 'NotAccepted' ||
              order.orderStatus === 'Pending' ||
              order.orderStatus === 'StoreCancelled'
            );
          }

          function checkOrdersOnTheirWay(order) {
            return order.orderStatus === 'LeftStore';
          }

          function checkOrdersOnLocal(order) {
            return order.orderStatus === 'ArrivedAtCustomer';
          }

          function checkOrdersFinished(order) {
            return order.orderStatus === 'Finished';
          }

          self.tickets = response;

          self.ordersConfirmed = response.filter(checkOrdersConfirmed).length;
          self.ordersOnTheirWay = response.filter(checkOrdersOnTheirWay).length;
          self.ordersOnLocal = response.filter(checkOrdersOnLocal).length;
          self.ordersFinished = response.filter(checkOrdersFinished).length;

          self.isGettingTickets = false;
        }, function (reason) {
          $log.error('ticketsService.getTickets', reason);

          toaster.pop('error', '¡Ups! No se pueden cargar los datos del pedido.');

          self.isGettingTickets = false;
        });
    },
    resetTickets: function () {
      self.tickets = [];
    },
    watchSortTerm: function () {
      $rootScope.$watch(function () {
        return self.sortTerm;
      }, function (newVal) {
        if (angular.isDefined(newVal)) {
          self.getTickets(sessionStorage.getItem('cnpj'));
        }
      });
    }
  };

  // self.getTickets();
  // self.watchSortTerm();

  return self;
}
