angular
  .module('app')
  .service('firebaseAuthService', firebaseAuthService);

/** @ngInject */
function firebaseAuthService($rootScope, $log, $q, $state, Slug, toaster, orderService, firebaseAuthFactory) {
  var self = {
    // OBJECTS
    currentUser: {},

    // FLAGS
    isAdmin: false,
    isCheckingUserLevel: false,
    isCreatingUser: false,
    isLogged: false,
    isSendingPasswordResetEmail: false,
    isSigningInWidhEmailAndPassword: false,
    isSigningInWithPopupGoogle: false,
    isSigningOut: false,
    isUpdatingUserEmail: false,
    isUpdatingUserPassword: false,

    // SERVICES
    orderService: orderService,

    // METHODS
    checkUserLevel: function () {
      if (self.isCheckingUserLevel) {
        return;
      }

      self.isCheckingUserLevel = true;

      var defer = $q.defer();

      firebase
        .database()
        .ref('/users/' + Slug.slugify(localStorage.getItem('currentUserEmail')))
        .once('value')
        .then(function (response) {
          self.isAdmin = response.val().admin;

          self.isCheckingUserLevel = false;

          defer.resolve();
        });

      return defer.promise;
    },
    createUser: function (data) {
      if (self.isCreatingUser) {
        return;
      }

      self.isCreatingUser = true;

      var defer = $q.defer();

      if (data.id === 0) {
        data.id = self.generateNewID();
      }

      firebaseAuthFactory
        .$createUserWithEmailAndPassword(data.email, data.password)
        .then(function (response) {
          firebase
            .database()
            .ref('users/' + Slug.slugify(data.email))
            .set({
              admin: data.admin,
              email: data.email,
              id: data.id,
              username: Slug.slugify(data.email)
            }, function (reason) {
              if (reason) {
                toaster.pop('error', '¡Ups! Ocurrio un error. Inténtalo de nuevo.');

                $log.error('firebaseAuthService.createUser', reason);

                self.isCreatingUser = false;
              } else {
                toaster.pop('success', 'ESTÁ BIEN. Usuario con correo electrónico "' + response.email + '" creado!');

                self.isCreatingUser = false;

                defer.resolve();
              }
            });
        }, function (reason) {
          if (reason.code === 'auth/email-already-in-use') {
            toaster.pop('error', '¡Ups! Ya hay un usuario registrado con este correo.');
          } else {
            toaster.pop('error', '¡Ups! Ocurrió un error al crear el usuario. Inténtalo de nuevo.');
          }

          $log.error('firebaseAuthService.createUser', reason);

          self.isCreatingUser = false;
        });

      return defer.promise;
    },
    generateNewID: function () {
      return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = Math.random() * 16 || 0;
        var v = c === 'x' ? r : (r & 0x3 || 0x8);

        return v.toString(16);
      });
    },
    resetPassword: function (email) {
      if (self.isSendingPasswordResetEmail) {
        return;
      }

      self.isSendingPasswordResetEmail = true;

      var defer = $q.defer();

      firebaseAuthFactory
        .$sendPasswordResetEmail(email)
        .then(function () {
          toaster.pop('info', 'Correo electrónico de restablecimiento de contraseña enviado!');

          self.isSendingPasswordResetEmail = false;

          defer.resolve();
        })
        .catch(function (reason) {
          if (reason.code === 'auth/user-not-found') {
            toaster.pop('error', 'No hay ningún usuario registrado con el correo electrónico facilitado.');
          }

          $log.error('firebaseAuthService.resetPassword()', reason);

          self.isSendingPasswordResetEmail = false;
        });

      return defer.promise;
    },
    signInWithEmailAndPassword: function (email, password) {
      if (self.isSigningInWithEmailAndPassword) {
        return;
      }

      self.isSigningInWithEmailAndPassword = true;

      var defer = $q.defer();

      firebaseAuthFactory
        .$signInWithEmailAndPassword(email, password)
        .then(function (response) {
          self.currentUser = response;

          $rootScope.attendantEmail = email;

          localStorage.setItem('currentUserEmail', email);

          if (
            email === 'isaac.silva@ops.tds.company' ||
            email === 'kleber.souza@ops.tds.company' ||
            email === 'renata.silva@ops.tds.company'
          ) {
            self.orderService.orderData.origin = 'whatsapp';
          }

          // self.checkUserLevel();

          self.isSigningInWithEmailAndPassword = false;

          defer.resolve();

          $state.go('mouraFacil');
        }, function (reason) {
          if (reason.code === 'auth/invalid-email') {
            toaster.pop('error', 'El correo electrónico proporcionado no es válido.');
          } else if (reason.code === 'auth/network-request-failed') {
            toaster.pop('error', 'No se puede conectar con el servidor. Vuelva a intentarlo en unos momentos.');
          } else if (reason.code === 'auth/too-many-requests') {
            toaster.pop('error', 'Acceso bloqueado temporalmente desde este dispositivo. Por favor, inténtelo de nuevo más tarde.');
          } else if (reason.code === 'auth/user-not-found') {
            toaster.pop('error', 'No hay ningún usuario con el correo electrónico facilitado.');
          } else if (reason.code === 'auth/wrong-password') {
            toaster.pop('error', 'La contraseña proporcionada no existe.');
          }

          // localStorage.clear();
          // sessionStorage.clear();

          $log.error('firebaseAuthService.signInWithEmailAndPassword', reason);

          self.isSigningInWithEmailAndPassword = false;
        });

      return defer.promise;
    },
    signInWithPopupGoogle: function () {
      if (self.isSigningInWithPopupGoogle) {
        return;
      }

      self.isSigningInWithPopupGoogle = true;

      firebaseAuthFactory
        .$signInWithPopup('google')
        .then(function (response) {
          self.currentUser = response.user;

          localStorage.setItem('currentUserEmail', self.currentUser.email);

          toaster.pop('success', '¡Hola, bienvenido de nuevo!');

          self.isSigningInWithPopupGoogle = false;
        })
        .catch(function (reason) {
          $log.error('firebaseAuthService.signInWithPopupGoogle', reason);

          toaster.pop('error', reason.message);

          self.isSigningInWithPopupGoogle = false;
        });
    },
    signOut: function () {
      if (self.isSigningOut) {
        return;
      }

      self.isSigningOut = true;

      firebaseAuthFactory
        .$signOut()
        .then(function () {
          // localStorage.clear();
          // sessionStorage.clear();

          self.isSigningOut = false;

          $state.go('signIn');
        }, function (reason) {
          $log.error('firebaseAuthService.signOut', reason);

          self.isSigningOut = false;
        });
    },
    updateUserEmail: function (email) {
      if (self.isUpdatingUserEmail) {
        return;
      }

      self.isUpdatingUserEmail = true;

      var defer = $q.defer();

      firebaseAuthFactory
        .$updateEmail(email)
        .then(function () {
          toaster.pop('success', 'ESTÁ BIEN. ¡Restablecimiento de correo electrónico!');

          self.isUpdatingUserEmail = false;

          defer.resolve();
        }, function (reason) {
          toaster.pop('error', '¡Ups! Ocurrió un error al restablecer el correo electrónico. Inténtalo de nuevo.');

          $log.error('firebaseAuthService.updateUserEmail', reason);

          self.isUpdatingUserEmail = false;
        });

      return defer.promise;
    },
    updateUserPassword: function (password) {
      if (self.isUpdatingUserPassword) {
        return;
      }

      self.isUpdatingUserPassword = true;

      var defer = $q.defer();

      firebaseAuthFactory
        .$updatePassword(password)
        .then(function () {
          toaster.pop('success', 'ESTÁ BIEN. ¡Restablecimiento de contraseña!');

          self.isUpdatingUserPassword = false;

          defer.resolve();
        }, function (reason) {
          if (reason.code === 'auth/requires-recent-login') {
            toaster.pop('error', '¡Ups! Esta operación es delicada y requiere autenticación reciente. Vuelva a iniciar sesión antes de volver a intentarlo.');
          } else {
            toaster.pop('error', '¡Ups! Ocurrió un error al restablecer la contraseña. Inténtalo de nuevo.');
          }

          $log.error('firebaseAuthService.updateUserPassword', reason);

          self.isUpdatingUserPassword = false;
        });

      return defer.promise;
    }
  };

  // CALLS
  firebaseAuthFactory.$onAuthStateChanged(function (currentUser) {
    var currentState = $state.$current.name;

    if (currentUser) {
      // self.checkUserLevel();

      self.isLogged = true;
    } else {
      self.isLogged = false;

      $state.go('signIn');
    }

    if (currentUser && currentState === 'signIn') {
      $state.go('mouraFacil');
    }
  });

  return self;
}
