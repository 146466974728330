angular
  .module('app')
  // COMMONS
  .constant('TDS_GEOLOC', 'https://geoloc.sodet.com.br/api/v1')

  // STAGING
  // .constant('FIREBASE_API_KEY', 'AIzaSyD2LAw3LmSf4z9gT9sMyOCunRg3YoyDYRY')
  // .constant('FIREBASE_AUTH_DOMAIN', 'moura-ya-asistencia-dev.firebaseapp.com')
  // .constant('FIREBASE_DATABASE_URL', 'https://moura-ya-asistencia-dev-default-rtdb.firebaseio.com/')
  // .constant('FIREBASE_MESSAGING_SENDER_ID', '895989851923')
  // .constant('FIREBASE_PROJECT_ID', 'moura-ya-asistencia-dev')
  // .constant('FIREBASE_STORAGE_BUCKET', 'moura-ya-asistencia-dev.appspot.com')
  // .constant('MOURA_FACIL_API', 'https://mf-api-staging.herokuapp.com/api/v1')
  // .constant('MOURA_FACIL_CALL_MONITOR_API', 'https://mf-call-monitor-dev.herokuapp.com/api/v1')
  // .constant('MOURA_FACIL_DIGITAL_SERVICES_API', 'https://mf-digital-services-pr-191.herokuapp.com')
  // .constant('MOURA_FACIL_REALTIME_API', 'https://mf-realtime-staging.herokuapp.com/v1')
  // .constant('MOURA_SEARCH_API_KEY', '9ppcKLY5KW806Cu1epJHT6yIJm3EEwni7OYXVTwx')
  // .constant('MOURA_SEARCH_API', 'https://moura-search-argentina-dev.herokuapp.com/api/v1')
  // .constant('MOURA_SEARCH_API_V2', 'https://moura-search-argentina-dev.herokuapp.com/api/v2')
  // .constant('MOURA_SERVICES_API', 'https://ms-argentina-dev.herokuapp.com/api/v1');

  // DEPLOY
  .constant('FIREBASE_API_KEY', 'AIzaSyDYraHXmiA9QsU7blP3FoAcXo6Nf_ZIoBA')
  .constant('FIREBASE_AUTH_DOMAIN', 'moura-ya-asistencia.firebaseapp.com')
  .constant('FIREBASE_DATABASE_URL', 'https://moura-ya-asistencia-default-rtdb.firebaseio.com/')
  .constant('FIREBASE_MESSAGING_SENDER_ID', '641607774724')
  .constant('FIREBASE_PROJECT_ID', 'moura-ya-asistencia')
  .constant('FIREBASE_STORAGE_BUCKET', 'moura-ya-asistencia.appspot.com')
  .constant('MOURA_FACIL_API', 'https://mf-api-prod.herokuapp.com/api/v1')
  .constant('MOURA_FACIL_CALL_MONITOR_API', 'https://callmon.mourafacil.com/api/v1')
  .constant('MOURA_FACIL_DIGITAL_SERVICES_API', 'https://api.staging.mourafacil.com.br')
  .constant('MOURA_FACIL_REALTIME_API', 'https://mf-realtime-staging.herokuapp.com/v1')
  .constant('MOURA_SEARCH_API_KEY', '9ppcKLY5KW806Cu1epJHT6yIJm3EEwni7OYXVTwx')
  .constant('MOURA_SEARCH_API', 'https://moura-search-argentina.herokuapp.com/api/v1')
  .constant('MOURA_SEARCH_API_V2', 'https://moura-search-argentina.herokuapp.com/api/v2')
  .constant('MOURA_SERVICES_API', 'https://ms-argentina.herokuapp.com/api/v1');
